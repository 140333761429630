import React from "react";
import TextField from "@mui/material/TextField";

export const Input = ({ name, label, value, onChange, error, fullWidth }) => {
  return (
    <TextField
      fullWidth={fullWidth}
      className="inputContainer"
      variant="outlined"
      error={error}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      helperText={error}
    />
  );
};
