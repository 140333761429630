import React, { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik";
import "./../assets/css/generator.scss";
import { Container, Row } from "reactstrap";
import generatorImage from "../images/generatorCV.svg";
import { Input } from "../components/generatorComponents/input";
import { FormControl, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { MultipleSelect } from "../components/generatorComponents/multipleSelect";
import { Button } from "@mui/material";
import modalX from "../images/modalX.svg";
import iconError from "../images/errIcon.svg";
import {
  abilities,
  technologies,
} from "../components/generatorComponents/date";
import { InputLangugages } from "../components/generatorComponents/inputLangugages";
import { InputProjects } from "../components/generatorComponents/inputProjects";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { Oval } from  'react-loader-spinner'
import * as y from "yup";
import request from "graphql-request";
import xIcon from "./../images/x-icon.svg";
import { useWidth } from "../../hooks/useWidth";

const GeneratorPage = ({ modal, close, positionDev, pageContext }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading,setIsLoading] = useState(false)
  const { isDesktop } = useWidth();
  
  const showError = () => {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, 5000);
  };
  const [phrase, setPhrase] = useState(null);
  
  const sendEmail = (values) => {
    setIsLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(values);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://presslysend.herokuapp.com/send", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result === "OK") {
          window.location = `/thanksForSend`;
        } else if(result !== "OK") {
          setIsError(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `  {
          phrase(locale: "${pageContext.intl.language}") {
            fullname
            email
            validationRequired
            task
            companyName
            from
            to
            phone
            send
            availibilitySince
            aboutYou
            tasks
            addTask
            delete
            still
            editProject
            comeback
            mustBeString
            projectName
            technologyStack
            differentTechnology
            abilities
            endDateTimeMustBeAfter
            differentAbilities
            languages
           language
            level
            add
            addProject
            editProject
            invalidEmail
            validationPhone
            validationTooShort
            validationTooLong
            minThreeElements
            MaxTenElements
            valueAlreadyAdded
            selectDate
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setPhrase(items.phrase);
    });
  }, []);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = y.object().shape({
    fullName: y
      .string()
      .min(2, phrase?.validationTooShort)
      .max(50, phrase?.validationTooLong)
      .required(phrase?.validationRequired),
    email: y
      .string()
      .email(phrase?.invalidEmail)
      .required(phrase?.validationRequired),
    phone: y.string().matches(phoneRegExp, phrase?.validationPhone),
    availability: y.date().typeError(phrase?.selectDate).required(phrase?.validationRequired),
    aboutYou: y
      .string()
      .min(2, phrase?.validationTooShort)
      .required(phrase?.validationRequired),
    abilities: y
      .array()
      .min(3, phrase?.minThreeElements)
      .max(10, phrase?.MaxTenElements),
    technologies: y
      .array()
      .min(3, phrase?.minThreeElements)
      .max(10, phrase?.MaxTenElements),
  });

  const { errors, values, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      initialValues: {
        fullName: "",
        email: "",
        companyName: "",
        phone: "",
        availability: null,
        aboutYou: "",
        technologies: [],
        abilities: [],
        languages: [],
        projects: [],
      },
      validationSchema,
      onSubmit:  (values) => {
        sendEmail(values);
      },
    });

  const HeaderGenerator = (
    <>
      {modal ? (
        <div
          className="headerContainer"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "20px 5px",
          }}
        >
          <label className="generatorTitle">
            Generator CV (dla stanowiska:{" "}
            {positionDev ? positionDev : "Nie wybrano"} )
          </label>
          <img src={modalX} onClick={close} />
        </div>
      ) : (
        <div className="headerContainer">
          <img src={generatorImage} width="256" height="233" />
          <label className="generatorTitle">Generator CV</label>
        </div>
      )}
    </>
  );

  return (
    <Container className="generatorContainer">
      <div
        style={{ opacity: isError ? "1" : "0" }}
        className="generator__error"
        onClick={() => setIsError(false)}
      >
        <img
          className="generator__error--icon"
          src={xIcon}
          width={!isDesktop ? "16px" : "12px"}
          height={!isDesktop ? "16px" : "12px"}
        />
        <img
          src={iconError}
          width={isDesktop ? "48px" : "32px"}
          height={isDesktop ? "48px" : "32px"}
        />
        <div className="generator__container">
          <h1 className="generator__error--header">Ups</h1>
          <p className="generator__error--label">
            Coś poszło nie tak, spróbuj ponownie{" "}
          </p>
        </div>
      </div>
      {HeaderGenerator}
      {phrase && (
        <form onSubmit={handleSubmit}>
          <div className="inputRows">
            <Input
              label={phrase.fullname}
              value={values.fullName}
              name="fullName"
              onChange={handleChange}
              error={touched.fullName && errors.fullName}
            />
            <Input
              label={phrase.email}
              value={values.email}
              name="email"
              onChange={handleChange}
              error={touched.fullName && errors.email}
            />
          </div>
          <div className="inputRows">
            <Input
              label={phrase.companyName}
              value={values.companyName}
              name="companyName"
              onChange={handleChange}
            />
            <Input
              label={phrase.phone}
              value={values.phone}
              name="phone"
              onChange={handleChange}
              error={touched.phone && errors.phone}
            />
          </div>
          <div className="inputRows">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date()}
                label={phrase.availibilitySince}
                inputFormat="MM/dd/yyyy"
                value={values.availability}
                helperText="Custom error message"
                onChange={(value) => setFieldValue("availability", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={touched.availability && errors.availability}
                    helperText={touched.availability && errors.availability}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="inputRows">
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label={phrase.aboutYou}
              multiline
              rows={4}
              onChange={(e) => {
                setFieldValue("aboutYou", e.target.value);
              }}
              value={values.aboutYou}
              error={touched.aboutYou && errors.aboutYou}
              helperText={touched.aboutYou && errors.aboutYou}
            />
          </div>
          <div className="inputRows">
            <MultipleSelect
              phrase={phrase}
              error={touched.technologies && errors.technologies}
              value={values.technologies}
              setFieldValue={setFieldValue}
              name="technologies"
              items={technologies}
              label={phrase.technologyStack}
              subLabel={phrase.differentTechnology}
              buttonLabel={phrase.add}
            />
            <MultipleSelect
              phrase={phrase}
              buttonLabel={phrase.add}
              error={touched.abilities && errors.abilities}
              value={values.abilities}
              setFieldValue={setFieldValue}
              name="abilities"
              items={abilities}
              label={phrase.abilities}
              subLabel={phrase.differentAbilities}
            />
          </div>
          <InputLangugages
            phrase={phrase}
            sendMyLangugages={(languages) =>
              setFieldValue("languages", languages)
            }
          />
          <InputProjects
            phrase={phrase}
            sendMyProjects={(projects) => {
              setFieldValue("projects", projects);
            }}
          />
          {modal ? (
            <div
              className="buttonSubmitContainer"
              style={{
                padding: "64px 16px 16px 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={close}
                className="addProjectModal__addProject"
                size="large"
                variant="contained"
                type="submit"
              >
                {"< Wróć"}
              </Button>
              <Button
                onClick={() => handleSubmit()}
                className="addProjectModal__addProject"
                size="large"
                variant="contained"
              >
                {isLoading ?<Oval
                  height="20"
                  width="20"
                  color='white'
                  ariaLabel='loading'
                />: <> {phrase.send}</>}
              </Button>
            </div>
          ) : (
            <div className="buttonSubmitContainer">
              <FormControl
                sx={{ width: { md: "30%", sx: "100%" } }}
                className="buttonSubmitContainer__FormControl"
              >
                <Button
                  onClick={() => handleSubmit()}
                  className="addProjectModal__addProject"
                  size="large"
                  variant="contained"
                >
                  {isLoading ?<Oval
                    height="20"
                    width="20"
                    color='white'
                    ariaLabel='loading'
                  />: <> {phrase.send}</>}
              </Button>
              </FormControl>
            </div>
          )}
        </form>
      )}
    </Container>
  );
};

export default GeneratorPage;
