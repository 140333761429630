import React, { useEffect, useState } from "react";
import { CardGroup, Card } from "reactstrap";
import editIcon from "./../../images/editIcon.svg";
import trash from "./../../images/trash.svg";
import addProject from "./../../images/addProject.svg";
import xIcon from "./../../images/x.svg";
import { AddProjectModal } from "./addProjectModal";
import moment from "moment";
import trashBlue from "./../../images/blue/trash.svg";
import wall from "./../../images/blue/wall.svg";
import edit from "./../../images/blue/edit.svg";

export const InputProjects = ({sendMyProjects,phrase}) => {
  const [projects, setProjects] = useState([]);
  
  
  useEffect(()=>{
    sendMyProjects(projects)
  },[projects])
  
  const Project = ({ editProject, removeProject, index, project }) => {
    return (
      <Card className="projectContainer">
        <div className="hover">
          <img src={edit} onClick={() => editProject(index)} />
          <img src={wall} />
          <img className="generatorTrash" src={trashBlue} onClick={() => removeProject(index)} />
        </div>
        <div className="projectContainer__content">
          <div className="labelValueContainer">
            <div className="label">Project:</div>
            <div className="value">{project.projectName}</div>
          </div>
          <div className="labelValueContainer">
            <div className="label">Stack:</div>
            <div className="value">
              {project?.projectTechnologies?.join(", ")}
            </div>
          </div>
          <div className="labelValueContainer">
            <div className="label">Period:</div>
            <div className="value">
              {moment(project.projectStart).format("L")} -
              {" "}{moment(project.projectEnd).format("L")}
            </div>
          </div>
          <div className="labelValueContainer">
            <div className="label">Tasks:</div>
            <div className="value">
              <ul style={{ marginLeft: "16px", padding: "0" }}>
                {project?._tasks?.map((e) => {
                  return <li>{e}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="projectContainer__button">
          <img src={editIcon} onClick={() => editProject(index)} />
          <img src={trash} onClick={() => removeProject(index)}/>
        </div>
      </Card>
    );
  };

  const AddProject = () => {
    return (
      <Card
        className="projectContainer addProjectContainer"
        onClick={() => setCurrentProject({})}
      >
        <img src={addProject} width="160px" />
        <label className="addProjectContainer__label">{phrase.addProject}</label>
      </Card>
    );
  };

  

  const [currentProject, setCurrentProject] = useState();

  return (
    <>
      {currentProject && (
        <AddProjectModal
          phrase={phrase}
          project={currentProject}
          addProject={(project) => {
            setProjects([...projects, project]);
          }}
          editProject={(project) => {
            const _projects = projects;
            _projects[project.index] = project;
            sendMyProjects(_projects)
            setProjects(_projects);
          }}
          close={() => setCurrentProject()}
        />
      )}
      <CardGroup className="inputProjectContainer">
        {projects.map((project, index) => (
          <Project
            editProject={(index) =>
              setCurrentProject({
                ...projects[index],
                index,
              })
            }
            project={project}
            index={index}
            removeProject={(index) =>
              setProjects(projects.filter((p, i) => i !== index))
            }
          />
        ))}
        {projects.length < 10 && <AddProject />}
      </CardGroup>
    </>
  );
};
