import { useEffect, useState } from "react";

export const useWidth = () => {
  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(window?.innerWidth);
    window?.addEventListener("resize", () => setWidth(window?.innerWidth));

    return () => {
      window?.removeEventListener("resize", () => setWidth(window?.innerWidth));
    };
  }, [width]);

  const isDesktop = width > 992;
  const isMobile = width <= 992;

  return {
    width,
    isDesktop,
    isMobile,
  };
};
