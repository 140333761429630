import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import trash from "./../../images/trash.svg";
import { useWidth } from "../../../hooks/useWidth";

export const InputLangugages = ({ sendMyLangugages, phrase }) => {
  const languages = ["Polski", "Angielski", "Niemiecki"];
  const levels = [
    "A1 – Beginner",
    "A2 – Pre-Intermediate",
    "B1 – Intermediate",
    "B2 – Upper-Intermediate",
    "C1 – Advanced",
    "C2 – Proficient",
    "Native",
  ];

  const { width } = useWidth();

  const [lang, setLang] = useState("");
  const [level, setLevel] = useState("");

  const [myLanguages, setMyLanguages] = useState([]);

  useEffect(() => {
    sendMyLangugages(myLanguages);
  }, [myLanguages]);
  

  const addNewLangugage = () => {
    if (lang === "") {
      setErrorLang("required");
    }
    if (level === "") {
      setErrorLevel("required");
    }
    if (level !== "" && lang !== "") {
      setErrorLang(null);
      setErrorLevel(null);
      setMyLanguages([
        ...myLanguages,
        {
          lang,
          level,
        },
      ]);
      setLang("");
      setLevel("");
    }
  };

  const deleteLangugage = (index) => {
    const newMyLanguages = myLanguages.filter((e,number)=> number !== index)
   setMyLanguages(newMyLanguages)
  };
  

  const [errorLang, setErrorLang] = useState(null);
  const [errorLevel, setErrorLevel] = useState(null);

  const ReadyLangugage = ({ lang, level, index }) => {
    return (
      <div className="inputRows readyLanguages">
        <FormControl sx={{ width: "32%" }}>
          <InputLabel sx={{ background: "white" }}>
            {phrase.languages}
          </InputLabel>
          <Select
            error={errorLang}
            value={lang}
            disabled
            label={phrase.language}
          >
            {languages.map((language) => (
              <MenuItem value={language}>{language}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "32%" }}>
          <InputLabel htmlFor="grouped-select">{phrase.level}</InputLabel>
          <Select
            error={errorLevel}
            value={level}
            disabled
            label={phrase.level}
          >
            {levels.map((level) => (
              <MenuItem value={level}>{level}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: "32%",
            display: "flex",
            justifyContent: { xs: "flex-end", md: "center" },
            flexDirection: { xs: "row", md: "column" },
          }}
        >
          {width < 768 ? (
            <Button
              style={{ maxHeight: "56px" }}
              variant="outlined"
              sx={{ width: "40%", borderColor: "#ea8484;", padding: 0 }}
            >
              <img
                className="generatorTrash"
                onClick={() => deleteLangugage(index)}
                src={trash}
                alt=""
                style={{ margin: ".5rem" }}
                width="20px"
              />
            </Button>
          ) : (
            <img
              className="generatorTrash"
              onClick={() => deleteLangugage(index)}
              src={trash}
              alt=""
              style={{ margin: ".5rem" }}
              width="20px"
            />
          )}
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <div className="generatorLabel">{phrase.languages}</div>
      {myLanguages.map((props, index) => (
        <ReadyLangugage {...{ ...props }} index={index} />
      ))}
      {myLanguages.length < 3 && (
        <div className="inputRows languages">
          <FormControl sx={{ width: "32%" }}>
            <InputLabel sx={{ background: "white" }}>
              {phrase.language}
            </InputLabel>
            <Select
              value={lang}
              onChange={(e) => setLang(e.target.value)}
              label={phrase.language}
            >
              {languages
                .filter((e) => !myLanguages.map((l) => l.lang).includes(e))
                .map((language) => (
                  <MenuItem value={language}>{language}</MenuItem>
                ))}
            </Select>
            {errorLang && <FormHelperText error> {errorLang}</FormHelperText>}
          </FormControl>
          <FormControl sx={{ width: "32%" }}>
            <InputLabel htmlFor="grouped-select">Poziom</InputLabel>
            <Select
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              label={phrase.level}
            >
              {levels.map((level) => (
                <MenuItem value={level}>{level}</MenuItem>
              ))}
            </Select>
            {errorLevel && <FormHelperText error> {errorLevel}</FormHelperText>}
          </FormControl>

          <FormControl sx={{ width: "30%" }}>
            <Button
              onClick={addNewLangugage}
              sx={{ height: "100%",maxHeight:'52px' }}
              variant="contained"
            >
              {phrase.add}
            </Button>
          </FormControl>
        </div>
      )}
    </>
  );
};
