export const abilities = [
  "elastyczność",
  "umiejętność rozwiązywania problemów",
  "akceptowanie opinii i konstruktywnej krytyki",
  "pewność siebie",
  "kreatywność",
  "zdolności przywódcze",
];

export const technologies = [
  "JavaScript",
  "Net",
  "Angular",
  "Python",
  "Node",
  "PHP",
  "AngularJs",
];
