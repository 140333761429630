import xIcon from "../../images/x.svg";
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Input } from "./input";
import { MultipleSelect } from "./multipleSelect";
import { technologies } from "./date";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import trash from "../../images/trash.svg";
import plusIcon from "../../images/plusIconBlue.svg";

import * as y from "yup";

export const AddProjectModal = ({
  editProject,
  addProject,
  close,
  phrase,
  project,
}) => {
  const {
    projectName,
    projectTechnologies,
    projectStart,
    projectEnd,
    _tasks,
    index,
  } = project;


  const SignupSchema = y.object().shape({
    projectName: y
      .string()
      .min(2, phrase?.validationTooShort)
      .max(50, phrase?.validationTooLong)
      .required(phrase?.validationRequired),
    projectTechnologies: y.array().min(3, phrase?.minThreeElements),
    technologies: y.array().min(3, phrase?.minThreeElements),
    projectStart: y.date().typeError(phrase?.selectDate).nullable().required(phrase?.validationRequired),
    projectEnd: y
      .date().typeError(phrase?.selectDate).nullable()
      .required(phrase?.validationRequired).min(y.ref("projectStart"), phrase?.endDateTimeMustBeAfter),
  });

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        projectName: projectName || "",
        projectTechnologies: projectTechnologies || [],
        projectStart: projectStart || null,
        projectEnd: projectEnd || null,
      },
      validationSchema: SignupSchema,
      onSubmit: (values) => {
        close();
        typeof index === "number"
          ? editProject({
              ...values,
              _tasks: tasks,
              index,
            })
          : addProject({
              ...values,
              _tasks: tasks,
            });
      },
    });

  const [stillProgress, setStillProgress] = useState(false);

  console.log(errors.projectStart)
  useEffect(() => {
    const date = new Date();
    if (stillProgress) {
      setFieldValue("projectEnd", date);
    }
  }, [stillProgress]);

  const [tasks, setTasks] = useState(_tasks || [""]);

  return (
    <div className="addProjectModal">
      <div className="addProjectModal__content">
        <div>
          <div className="addProjectModal__content--header">
            <label className="addProjectModal__content--header--label">
              {typeof index !== "undefined"
                ? phrase.editProject
                : phrase.addProject}
            </label>
            <img src={xIcon} onClick={close} />
          </div>
          <div className="addProjectModal__form">
            <form onSubmit={handleSubmit}>
              <div className="inputRows">
                <Input
                  error={touched.projectName && errors.projectName}
                  label={phrase.projectName}
                  value={values.projectName}
                  name="projectName"
                  onChange={handleChange}
                />
              </div>
              <div className="inputRows">
                <MultipleSelect
                  error={
                    touched.projectTechnologies && errors.projectTechnologies
                  }
                  width="100%"
                  value={values.projectTechnologies}
                  setFieldValue={setFieldValue}
                  name="projectTechnologies"
                  items={technologies}
                  label={phrase.technologyStack}
                  subLabel={phrase.differentTechnology}
                  buttonLabel={phrase.add}
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="inputRows start">
                  <DatePicker
                    label={`${phrase.from}:`}
                    inputFormat="MM/dd/yyyy"
                    value={values.projectStart}
                    onChange={(value) => setFieldValue("projectStart", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={touched.projectStart && errors.projectStart}
                        helperText={touched.projectStart && errors.projectStart}
                      />
                    )}
                  />
                  <div className="d-flex flex-column">
                    <DatePicker
                      label={`${phrase.to}:`}
                      disabled={stillProgress}
                      inputFormat="MM/dd/yyyy"
                      value={values.projectEnd}
                      onChange={(value) => setFieldValue("projectEnd", value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.projectEnd && errors.projectEnd}
                          helperText={touched.projectEnd && errors.projectEnd}
                        />
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stillProgress}
                          onChange={(e) => {
                            setStillProgress(!stillProgress);
                          }}
                        />
                      }
                      label={phrase.still}
                    />
                  </div>
                </div>
              </LocalizationProvider>
              <div className="generatorLabel">{phrase.tasks}:</div>
              {tasks.map((e, index) => {
                return (
                  <TextField
                    label={`${phrase.task} ${index + 1}`}
                    id="outlined-start-adornment"
                    sx={{ marginTop: "1rem" }}
                    fullWidth
                    name="task"
                    value={tasks[index]}
                    onChange={({ target: { value } }) => {
                      const _tasks = [...tasks];
                      _tasks[index] = value;
                      setTasks(_tasks);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={() => {
                            setTasks(tasks.filter((e, i) => i !== index));
                          }}
                          variant="contained"
                          color="error"
                        >
                          <img
                            width="16px"
                            style={{ marginRight: ".5rem" }}
                            src={trash}
                          />
                          {phrase.delete}
                        </Button>
                      ),
                    }}
                  />
                );
              })}

              <Button
                disabled={tasks.length === 4}
                onClick={() => setTasks([...tasks, ""])}
                className="addProjectModal__addTask"
                size="large"
                variant="outlined"
              >
                <img src={plusIcon} />
                {phrase.addTask}
              </Button>
            </form>
          </div>
        </div>
        <div className="addProjectModal__bottom">
          <Button
            onClick={() => close()}
            className="addProjectModal__comeback"
            size="large"
            variant="outlined"
          >
            {phrase.comeback}
          </Button>
          <Button
            onClick={() => handleSubmit()}
            className="addProjectModal__addProject"
            size="large"
            variant="contained"
          >
            {typeof index === "number" ? phrase.editProject : phrase.addProject}
          </Button>
        </div>
      </div>
    </div>
  );
};
