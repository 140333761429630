import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import plusIcon from "./../../images/plusIcon.svg";
import * as yup from "yup";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const MultipleSelect = ({
  buttonLabel,
  label,
  items,
  subLabel,
  phrase,
  name,
  error,
  setFieldValue,
  value,
  width = "48%",
}) => {
  const [localItem, setLocalItems] = useState(items);

  const [localError, setLocalError] = useState(null);

  const [customItem, setCustomItem] = useState("");

  const addCustomItem = () => {
    let schema = yup.object().shape({
      customItem: yup.string().min(1, phrase?.mustBeString),
    });

    schema
      .isValid({
        customItem,
      })
      .then(function (valid) {
        if (valid && !localItem.includes(customItem)) {
          setLocalError(null);
          setLocalItems([...localItem, customItem]);
          setFieldValue(name, [...value, customItem]);
          setCustomItem("");
        } else {
          setLocalError(localItem.includes(customItem) ? phrase.valueAlreadyAdded :phrase.mustBeString);
        }
      });
  };

  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ width }}>
      <InputLabel sx={{ background: "white", padding: "0 .5rem " }}>
        {label}
      </InputLabel>
      <Select
        multiple
        name={name}
        value={value}
        error={error}
        onChange={handleChange}
        input={<OutlinedInput id={label} label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {localItem.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={value.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error> {error}</FormHelperText>}
      <TextField
        error={localError}
        helperText={localError}
        label={subLabel ? subLabel : "Inna technologia"}
        id="outlined-start-adornment"
        sx={{ marginTop: "1rem" }}
        fullWidth
        value={customItem}
        onChange={(e) => setCustomItem(e.target.value)}
        InputProps={{
          endAdornment: (
            <Button onClick={addCustomItem} variant="contained">
              <img
                width="16px"
                style={{ marginRight: ".5rem" }}
                sty
                src={plusIcon}
              />
              {buttonLabel}
            </Button>
          ),
        }}
      />
    </FormControl>
  );
};
